/* Global CSS variables */
:root {
	--text-weight: 400;
	--titles-weight: 800;
	--block-round: 0;
	--border-radius: 0;
	--typography-spacing-vertical: 1rem;

	/*
	--font-family: "tex_gyre_adventorbold";
	--font-weight: normal;
	--spacing: 4rem;
	--spacing-block: 4rem;
	*/
}

/* Light scheme (Default) */
/* Can be forced with data-theme="light" */
[data-theme="light"],
:root:not([data-theme="dark"]) {
	--header-text-background:        rgba(255, 255, 255, 0.75);
	--header-logo-color: black;
}

/* Dark scheme (Auto) */
/* Automatically enabled if user has Dark mode enabled */
@media only screen and (prefers-color-scheme: dark) {
	:root:not([data-theme="light"]) {
	--header-text-background:        rgba(0, 0, 0, 0.75);
	--header-logo-color: white;
	}
}

/* Dark scheme (Forced) */
/* Enabled if forced with data-theme="dark" */
[data-theme="dark"] {
	--header-text-background:        rgba(0, 0, 0, 0.75);
	--header-logo-color: white;
}
