// Config
// ––––––––––––––––––––

// Enable <header>, <main>, <footer> inside <body> as a container
$enable-semantic-container: false !default;

// Enable .container and .container-fluid
$enable-class-container: true !default;

// Enable a centered viewport for <header>, <main>, <footer> inside <body>
// Fluid layout if disabled
$enable-viewport: true !default;

// Enable responsive spacings for <header>, <main>, <footer>, <section>, <article>
// Fixed spacings if disabled
$enable-responsive-spacings: true !default;

// Enable responsive typography
// Fixed root element size if disabled
$enable-responsive-typography: true !default;

// Enable .classes
// .classless version if disabled
$enable-classes: true !default;

// Enable .grid class
$enable-grid: true !default;

// Enable transitions
$enable-transitions: true !default;

// Enable overriding with !important
$enable-important: true !default;

// Responsive
// ––––––––––––––––––––

// xs: Extra small (portrait phones)
// sm: Small(landscape phones)
// md: Medium(tablets)
// lg: Large(desktops)
// xl: Extra large (large desktops)

// NOTE:
// To provide an easy and fine styling on each breakpoint
// we didn't use @each, @mixin or @include.
// That means you need to edit each CSS selector file to add a breakpoint

// Breakpoints
// 'null' disable the breakpoint
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// Viewports
$viewports: (
  // 'null' disable the viewport on a breakpoint
  sm: 510px,
  md: 700px,
  lg: 920px,
  xl: 1130px
) !default;
