/* Typography */
h2, h3, hgroup>:last-child {
	font-weight: 200;
}

h2, h3, h4, h5, h6 {
	margin-bottom: calc( 0.3 * var(--typography-spacing-vertical))
}

small {
	color: var(--muted-text);
	font-size: 0.8rem;
}

.pull-end {
	float: right;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Header */
.brand-name {
	font-family: "tex_gyre_adventorbold";
}

.hero {
	background-color: #394046;
	background-image: var(--hero-image);
	background-position: center;
	background-size: cover;

	h1, p, .brand-name {
		font-family: "tex_gyre_adventorbold";
	}

	header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: calc(var(--spacing) * 4) 0;
	}
	header hgroup>:last-child {
		color: var(--h3);
	}
	header hgroup {
		margin-top: calc(var(--spacing) * 4);
	}
	header hgroup > * {
		display: inline-block;
		float: left;
		clear: left;
		padding: .1em .4em;
		background-color: var(--header-text-background);
	}
}

#logo {
	color: var(--header-logo-color);

	width: auto;
	height: auto;

	#outline,
	#eye-left,
	#eye-right,
	#mouth {
		color: black;
	}

	#bit-b,
	#bit-i-lower,
	#bit-i-dot,
	#bit-t {
		color: var(--header-logo-color);
	}

	#ghost-body {
		color: var(--header-logo-color);
	}
}

nav svg#logo {
	width: auto;
	height: 32px;
}

.top-nav {
	position: fixed;
	top: 0px;
	background-color: var(--header-text-background);

	li.active {
		background-color: var(--contrast-focus);
	}
}


/* Main */
main.offset-nav {
	margin-top: calc(var(--block-spacing-vertical) * 1.2);
}
main.grid > * {
	margin-bottom: var(--spacing);
}

@media (min-width: 992px) {
	main.offset-nav {
		margin-top: var(--block-spacing-vertical);
	}

	main .grid {
		grid-column-gap: var(--spacing);
		grid-template-columns: auto 25%;
	}
}

form.grid {
	grid-row-gap: 0;
}

/* Aside nav */
aside {
	img {
		margin-bottom: .25rem;
	}

	p {
		margin-bottom: var(--spacing);
		line-height: 1.25;
	}
}

/* Table data */
table.fixed {
	table-layout: fixed;
	max-width: 100%;
}

/* Footer */
footer {
	a, a:hover, a:active, a:focus {
		text-decoration: none;
	}

	.grid div:first-child {
		text-align: left;
	}

	.grid div {
		text-align: center;
	}

	.grid div:last-child {
		text-align: right;
	}

	.privacy {
		text-align: center;
		margin-top: 2em;
	}
}

// Check if the height is at max 640px
@media only screen and (max-width: 600px) {
    .brand-name {
        display: none;
    }
}

@media only screen and (min-width: 640px) {
	.grid {
	    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
	}
}
